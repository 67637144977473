import React from 'react';
import AccountHeader from '../components/AccountHeader';
import { MpurseStateAndCard } from '../components/MpurseState';
import "../style/styles.css"
import { Link } from "gatsby";

const account = ({ children }) => {
  return (
    <AccountHeader>
      <div className="">
        <div className="w-full h-full py-4 md:py-12 mx-auto flex justify-center items-center">
          <div className='w-full'>
            <p className="font-bold text-xl md:text-3xl mx-auto">My account</p>
            <div className="flex flex-wrap justify-center md:justify-between items-start">
              <MpurseStateAndCard />
            </div>
          </div>
        </div>
      </div> 
      <div className="mt-2 mb-2 px-2 lg:px-48 text-2xl md:text-4xl font-bold text-center">
        <Link to="/account" className="mt-4 mb-4 px-12 md:pb-2 rounded-lg border-2 border-solid w-1/2 mx-auto flex justify-center items-center hover:opacity-75" style={{borderColor: 'rgb(222, 242, 217)'}}>Top</Link>
      </div>
    </AccountHeader>
  );
}

export default account;

