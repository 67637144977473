import React, {useState, useEffect, useContext } from "react";
import { SiteContext } from "./layout/Layout";
import { Link } from "gatsby";
import { addressShort, getAddressMP, getAssetMP, getMonacard } from "./Mpurse";
import '../css/style.css';

export function MpurseStateInfo() {
  const { state, dispatch } = useContext(SiteContext);
  useEffect(() => {
    try {
      getAddressMP().then((r) => {
        const addr = r;
        // const addr = addressShort(a)
        dispatch({ type:'ADDR_STATE', payload: addr });
      });
    }
    catch { return }

    // try {
    //   // ↓↓↓↓↓ ONにする
    //   window.mpurse.updateEmitter.removeAllListeners()
    //     .on('addressChanged', address => {
    //       const addr = addressShort(address);
    //       dispatch({ type:'ADDR_STATE', payload: addr });
    //     }
    //   )
    //   .on('stateChanged', isUnlocked => {
    //     if(!isUnlocked) {
    //       window.location.href = '/account';
    //     }
    //   });
    // }
    // catch{ return }
  }, []);
  return null;
}

export function MpurseStateAndCard() {
  const [ asset, setAsset ] = useState([]);
  const { state, dispatch } = useContext(SiteContext);
  const [check, setCheck] = useState(null);
  let assetTotalList = [];
  useEffect(() => {
    if(state.mpurseAddr === "") { 
      MpurseAssetReq();
    };
    
    // ↓↓↓↓↓ ONにする
    try {
      window.mpurse.updateEmitter.removeAllListeners()
      .on('addressChanged', address => {
        const addr = addressShort(address);
        dispatch({ type:'ADDR_STATE', payload: addr });
        setCheck(address);
        setAsset(null);
        MpurseAssetReq();
      });
    }
    catch { return }
  }, []);

  function MpurseAssetReq() {
    let monacoinBalance;
    let becomedCardAssetName = []; // カード化されたアセットの名前
    let notCardAssetName = []; 
    let joinedAssetName;
    let monacardList = [];
    let assetNameAndQuantity = [] // 所有している全てのアセット情報
    let assetNameList = [];
    assetTotalList = []; //初期化
    
    assetTotalList.push(
      <div className="mx-auto flex justify-center items-center">
        <div className="mt-16 mb-2 px-2 lg:px-48 text-2xl md:text-4xl font-bold text-center">
          <p>Please enable Mpurse.</p>
          <Link to="/mpurse" className="mt-4 mb-4 px-12 md:pb-2 rounded-lg border-2 border-solid w-1/2 mx-auto flex justify-center items-center hover:opacity-75" style={{borderColor: 'rgb(222, 242, 217)'}}>Mpurse?</Link>
        </div>
      </div>
    );
    setAsset(assetTotalList);

    getAddressMP().then((mpurseAddr) => {
      if(mpurseAddr == false) {
        assetTotalList = []; //初期化
        assetTotalList.push(
          <div className="mx-auto flex justify-center items-center">
            <div className="mt-16 mb-2 px-2 lg:px-48 text-2xl md:text-4xl font-bold text-center">
              <p>You Don't Have Mpurse Wallet.</p>
              <Link to="/mpurse" className="mt-4 mb-4 px-12 md:pb-2 rounded-lg border-2 border-solid w-1/2 mx-auto flex justify-center items-center hover:opacity-75" style={{borderColor: 'rgb(222, 242, 217)'}}>Mpurse?</Link>
            </div>
          </div>
        );
        setAsset(assetTotalList);
      } else {
        const a = mpurseAddr;
        const addr = addressShort(a)
        dispatch({ type:'ADDR_STATE', payload: addr });
      }

      // Monacoin Balance
      try {
        window.mpurse.counterBlock('get_chain_address_info', { addresses: [mpurseAddr] })
          .then((r) => {
            if(r.length === 1)
            monacoinBalance = r[0].info.balance;
            assetTotalList = []; //初期化
            assetTotalList.push(
              <div className="w-full mt-4 text-xl md:text-3xl mx-auto">
                <div className="w-full flex justify-between rounded-lg bg-gradient-to-r from-yellow-500 to-yellow-600 mt-4 mb-4 px-2 py-4 text-xl md:text-3xl mx-auto">
                  <div className="break-all text-base md:text-2xl px-2">
                    <p>MONA</p>
                    <p>{ monacoinBalance }</p>
                  </div>
                </div>
                <div className="w-full m-auto text-center">
                  <div class="loader">Loading...</div>
                </div>
              </div>
            );
            setAsset(assetTotalList);
          }).catch((e) => {}
        );
      }
      catch{}

      if(mpurseAddr == false){
        return
      } else {
      getAssetMP(mpurseAddr).then((r) => { // Mpurse から情報を取得
          for(let i in r){
            // Monacard 用に asset or assetlongname を取得
            // const assetName = (r[i].asset_longname === null) ? r[i].asset : r[i].asset_longname;
            const quantity = r[i].quantity;
            const assetName = "";
            if(r[i].fungible) {
              // トークンが FT の場合
              if(r[i].asset_longname === null) {
                assetName = r[i].asset;
              } else {
                assetName = r[i].asset_longname;
              }
              assetNameAndQuantity.push({ assetName: assetName, quantity: quantity, fungible: true, asset: "", asset_group: "" });
            } else {
              // トークンが NFT の場合
              assetName = r[i].asset;
              const assetGroup = r[i].asset_group;
              assetNameAndQuantity.push({ assetName: assetGroup, quantity: quantity, fungible: false, asset: assetName, asset_group: assetGroup });
            }
            assetNameList.push(assetName);
          }
          joinedAssetName = assetNameList.join(',');
        })
        .then(() => {
          getMonacard(joinedAssetName).then((r) => { // Mpurse からのアセット情報で Monacard リクエスト
            for(let i in r) {
              // カード化されているアセット情報。モナカードからのレスポンス
              const assetName = "";
              if(r[i].assetgroup === null) {
                assetName = (r[i].asset_longname == null) ? r[i].asset : r[i].asset_longname;
              } else {
                assetName = r[i].assetgroup;
              }
              
              for(let ii in assetNameAndQuantity) {
                // Monacard の情報に Mpurse から quantity を追加
                if(assetNameAndQuantity[ii].assetName === assetName){
                  r[i].quantity = assetNameAndQuantity[ii].quantity;
                  r[i].mergeAssetName = assetNameAndQuantity[ii].assetName;
                }
              }
              // becomedCardAssetNameへカード化されているトークン名を入れる
              // monacardAPIからはカード化されていないアセットの名前は省かれて返ってくる
              becomedCardAssetName.push(r[i].asset_common_name);
            }

            // assetNameListの中からカード化されていないアセットを見つける
            notCardAssetName = assetNameList.filter(r => !becomedCardAssetName.includes(r));
            for(let i in assetNameAndQuantity) {
              if(notCardAssetName.includes(assetNameAndQuantity[i].assetName)) {
                r.push(assetNameAndQuantity[i]);
              } else if(notCardAssetName.includes(assetNameAndQuantity[i].asset)) {
                // カード化されていないNFTにfungibleタグをつける
                assetNameAndQuantity[i].fungible = false;
                r.push(assetNameAndQuantity[i]);
              }
            }
            for(let i in r) {
              let a = r[i].assetName;
              if(becomedCardAssetName.includes(a)) {
                r[i].quantity = assetNameAndQuantity[i].quantity;
              }
            }
            monacardList = r;
          })
          .then(() => { // getAssetMP と getMonacard の情報をまとめる
            // Monacoin Baalance
            assetTotalList = []; //初期化
            assetTotalList.push(
              <div className="w-full mt-4 mx-auto">
                <div className="w-full flex justify-between rounded-lg bg-gradient-to-r from-yellow-500 to-yellow-600 mt-4 mb-4 px-2 py-4 text-xl md:text-3xl mx-auto">
                  <div className="font-medium md:font-semibold break-all text-base md:text-2xl px-2">
                    <p>MONA</p>
                    <p>{ monacoinBalance }</p>
                  </div>
                </div>
              </div>
            );
            // Monaparty Monacard Balance
            for(let i in monacardList) {
              let cardLink;
              let cardImage = new Boolean(true);
              const nft = ""; // NFTかどうかの判定
              if(monacardList[i].assetgroup != null || monacardList[i].fungible === false) { 
                nft = <p className="rounded-lg border-2 text-center md:w-16 w-10 text-sm md:text-xl">NFT</p>
              };
              if(monacardList[i].imgur_url === undefined) {
                cardImage = false;
              } else {
                if(monacardList[i].imgur_url.slice(0, 22) === "https://img.monaffy.jp") {
                  cardLink = monacardList[i].imgur_url.replace(/original/, "preview");
                } else if(monacardList[i].imgur_url.slice(-3) === "png") {
                  cardLink = monacardList[i].imgur_url.replace(".png", "l.png");
                } else if(monacardList[i].imgur_url.slice(-3) === "jpg") {
                  cardLink = monacardList[i].imgur_url.replace(".jpg", "l.jpg");
                } else {
                  cardLink = monacardList[i].imgur_url;
                }
              }
              if(cardImage) {
                let link = "https://card.mona.jp/explorer/card_detail?asset=" + monacardList[i].mergeAssetName;
                assetTotalList.push(
                  <div className="min-w-full md:min-w-0 md:w-1/2 text-xl md:text-3xl p-1">
                    <div className="w-full flex justify-between rounded-lg bg-gradient-to-r from-yellow-600 to-yellow-500 px-2 py-2 text-xl md:text-3xl mx-auto">
                      <div className="font-medium md:font-semibold relative w-1/2 break-all text-base md:text-2xl px-2">
                        <p className="absolute left-0 top-0">{ monacardList[i].mergeAssetName }<br/>{ nft }</p>
                        <p className="absolute bottom-0 left-0">{ monacardList[i].quantity }</p>
                      </div>
                      <a className="z-40 transform rotate-0 hover:rotate-6 hover:scale-105 w-1/2 flex justify-center items-center" href={ link }>
                        <img className="rounded-lg shadow-xl" src={cardLink} />
                      </a>
                    </div>
                  </div>
                );
              } else {
                assetTotalList.push(
                  <div className="min-w-full md:min-w-0 md:w-1/2 text-xl md:text-3xl p-1">
                    <div className="w-full flex justify-between rounded-lg bg-gradient-to-r from-yellow-600 to-yellow-500 px-2 py-2 text-xl md:text-3xl mx-auto">
                      <div className="font-medium md:font-semibold relative w-1/2 break-all text-base md:text-2xl px-2">
                        <p className="absolute left-0 top-0">{ monacardList[i].assetName }<br/>{ nft }</p>
                        <p className="absolute bottom-0 left-0">{ monacardList[i].quantity }</p>
                      </div>
                      <div className="font-medium md:font-semibold relative w-1/2 flex justify-center items-center">
                        <img className="rounded-lg shadow-xl" src={"/no_image.png"} />
                        <p className="absolute inset-0 text-base flex justify-center items-center">no image &#x1f97a;</p>
                      </div>
                    </div>
                  </div>
                );
              }
            }
            setAsset(assetTotalList);
          return assetTotalList;
          })
          .catch((e) => { console.log(e) });
        })
        .catch((e) => {
          // Monacoin Baalance
          assetTotalList.push(
            <div className="min-w-full md:min-w-0 mt-1 mx-1">
              <div className="w-full flex justify-between rounded-lg bg-gradient-to-r from-yellow-500 to-yellow-600 mt-4 mb-4 px-2 py-4 text-xl md:text-3xl mx-auto">
                <div className="font-medium md:font-semibold break-all text-base md:text-2xl px-2">
                  <p>MONA</p>
                  <p>{ monacoinBalance }</p>
                </div>
              </div>
            </div>
          );
          setAsset(assetTotalList);
          return assetTotalList;
        });
      }
    })
    .catch((e) => { console.log("error") });
  }
  if(assetTotalList != null){
    return asset;
  }
}
