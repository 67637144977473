import React from 'react';
import Layout from '../components/layout/Layout';
import { Link } from 'gatsby';
import "../style/styles.css"

const AccountHeader = ({ children }) => {
  return (
    <Layout>
      <div className="px-4">
        <div className="w-full h-full py-12 md:py-24 mx-auto flex justify-center items-center">
          <div className='w-full'>
            <div className="mb-4 text-xl md:text-3xl mx-auto flex justify-center items-center">
              <Link to="/account" className="underline font-bold px-2">ACCOUNT</Link>
              <Link to="/account/faucet_mp" className="underline font-bold px-2">KOJIKI</Link>
            </div>
            <main>{ children }</main>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AccountHeader;

